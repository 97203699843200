import {
  SiPython,

} from "react-icons/si";
import { DiDjango } from "react-icons/di";

interface LearningType {
  name: string;
  icon: any;
  link: string;
}

export const LearningData: LearningType[] = [
  {
    name: "Python",
    icon: <SiPython color='wheat' />,
    link: "https://www.python.org/",
  },
  {
    name: "Django",
    icon: <DiDjango color='wheat' />,
    link: "https://www.djangoproject.com/",
  },
];