
interface AboutType {
  firstName: string,
  lastName: string,
  about1: string,
  about2: string
}


export const AboutData: AboutType = {
  firstName: 'Alexis',
  lastName: 'Fourthies',
  about1: '<A Developer who loves to build cool things />',
  about2: 'Développeur React JS & TS',
};
