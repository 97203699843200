import { ImGithub, ImLinkedin } from "react-icons/im";
import { ImProfile } from "react-icons/im";
interface LinksType {
  title: string;
  icon: any;
  link: string;
}



export const LinksData: LinksType[] = [
  {
    title: "GitHub",
    icon: <ImGithub color='wheat' />,
    link: "https://github.com/Alexis-Fourthies",
  },
  {
    title: "LinkedIn",
    icon: <ImLinkedin color='wheat' />,
    link: "https://www.linkedin.com/in/alexis-fourthies/",
  },
  {
    title: "Resume",
    icon: <ImProfile color='wheat' />,
    link: "https://drive.google.com/file/d/1KgRpgy4rZq8YhwdxIiEo90BLRarSQOo8/view?usp=sharing",
  },
];
