interface ExperienceDataType {
  title: string;
  date: string;
  details: string[];
}

export const ExperienceData: ExperienceDataType[] = [
  {
    title: 'VEOLIA IS&T - Développeur React JavaScript & TypeScript (Stage fin de formation)',
    date: ' Octobre 2022 - Décembre 2022',
    details: [
      'Développement de plusieurs composants React à destination de l\'intranet', 
      'Objectif : Enrichir l\'expérience utilisateur avec la création d\'un mini dashboard où l\'on retrouve des infos utiles et des Call to Action pour mettre à jour ces infos', 
      'La finalité était avant tout de porter un regard critique sur le portail dev ainsi que le SDK mis à disposition par un de nos prestataires. Personne n\'avait encore expérimenté ce service, j\'ai donc montré qu\'il était exploitable et ai pesé le pour et le contre avec objectivité.', 
      'Overall une superbe expérience car dans la continuité de ma formation j\'ai pu être autonome, créer du contenu et agréablement surprendre mes collègues.',
    ],
  },
  {
    title: 'Mon autre vie : Property Manager (FIMINCO REIM)',
    date: 'Avant 2022',
    details: [
      'Gestion d\'un Parking : Changement de syndic, votes aux Assemblées Générales, recherche locataire, élaboration baux & conventions, amélioration du système de contrôle d\'accès, installation bornes recharges VAE.',
      'Gestion d\'un Parc technologique, valorisation de l\'architecture réseau du parc, optimisation des coûts liés à l\'informatique.',
      'Gestion locative du Groupe : Etat locatif, reddition de charges, synthèse EL, proratas lors de cession, gestion litiges, incidents...',
    ],
  },
];
