import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import { Player } from '@lottiefiles/react-lottie-player';
import { pageVariants, pageTransition } from "../../utils/FramerAnimation";
import styles from "./contact.module.scss";
import emailjs from "@emailjs/browser"

interface Props {}

const Contact = (props: Props) => {
  const serviceId = process.env.REACT_APP_SERVICE_ID as string;
  const templateId = process.env.REACT_APP_TEMPLATE_ID as string;
  const publicKey = process.env.REACT_APP_PUBLIC_KEY as string;
  const form = useRef<HTMLFormElement>(null);
const sendEmail = (e: React.FormEvent) => {
  e.preventDefault();

  if (form.current) {
    emailjs.sendForm(serviceId, templateId, form.current, publicKey).then(
      (result) => {
        alert("Le message a bien été envoyé. Merci de m'avoir contacté !");
        //console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  }}

  return (
    <div className={styles.contact}>
      <motion.div
        initial="init"
        animate="anim"
        exit="last"
        variants={pageVariants}
        transition={pageTransition}
        className={styles.wrapper}
      >
        <div className={styles.form}>
          <h3 className={styles.contactOpen}></h3>
          <form ref={form} onSubmit={sendEmail}>
            <input type="text" name="user_name" placeholder="Nom" required />
            <input type="email" name="user_email" placeholder="Email" required />
            <textarea
              name="message"
              cols={30}
              rows={5}
              placeholder="Votre Message"
              required
            ></textarea>
            <button type="submit">
              Envoyer
            </button>
          </form>
        </div>
        <div className={styles.lottie}>
          <Player
            autoplay
            src="https://assets7.lottiefiles.com/packages/lf20_5ryadlfr.json"
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      </motion.div>
    </div>
  );
};

export default Contact;
